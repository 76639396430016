
import type { PropType } from 'vue'
import { defineComponent } from 'vue'
import TmModal from '@/components/shared/modal/TmModal.vue'
import EmailPreview from '@/components/shared/templates/EmailPreview.vue'
import SatisfactionStatuses from '@/components/pages/tickets/settings/feedbackSurvey/SatisfactionStatuses.vue'

type FeedbackFormItem = {
  emailSubject: string;
  name: string;
  surveyHeading?: string;
  introMessage?: string;
  footerMessage: string;
  afterMessage?: string;
  satisfactions?: any[];
}

export default defineComponent({
  components: {
    SatisfactionStatuses,
    TmModal,
    EmailPreview,
  },
  props: {
    modalTitle: {
      type: String,
      required: true,
    },
    emailType: {
      type: String,
    },
    feedbackTextsModal: {
      type: Object as PropType<FeedbackFormItem>,
      required: true,
    },
  },
})
